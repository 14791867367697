#services {
    background-color: #05253C;
}

.service_item_div {
    text-align: center;
}

.services_container {
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.services_grid_item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.services_grid_item1 {
    height: 250px;
}

.services_grid_item2 {
    height: 200px;
}

.services_grid_item3 {
    height: 150px;
}

.services_grid_item4 {
    height: 100px;
}

.services_icon {
    width: 100%;
    color: #65E4C5;
}

.services_icon1 {
    height: 80px;
    transform: scale(3.0);
}

.services_icon2 {
    height: 60px;
    transform: scale(2.0);
}

.services_icon3 {
    height: 80px;
    transform: scale(2.0);
}

.services_icon_text {
    color: white;
    font-weight: bold;
    display: block;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
}

.services_icon_text1 {
    font-size: 1.875rem;
}

.services_icon_text2 {
    font-size: 1.60rem;
}

.services_icon_text3 {
    font-size: 1.20rem;
}

.services_icon_text4 {
    font-size: 1.00rem;
}