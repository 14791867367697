#footer {
    background-color: #05253C;
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Mukta, serif;
}

.icon_link {
    color: inherit;
}

.icon_link:hover {
    cursor:pointer;
}