#navbar {
    background-color: #05253C;
    color: white;
    padding-left: 0;
    padding-right: 0;
}

.long_navbar_buttons {
    margin: 16px 24px 16px 0;
    display: block;
    color: white;
    font-family: Mukta, serif;
}