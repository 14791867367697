.poster_text {
    background: rgba(58, 79, 57, 0.75);
    font-family: Salsa, serif;
    color: white;
    padding: 20px;
    text-align: center;
}

#poster_text1 {
    font-size: 2em;
    font-weight: bold;
}

#poster_text2 {
    font-size: 1.75em;
    font-weight: bold;
}

#poster_text3 {
    font-size: 1.75em;
    font-weight: bold;
}

#poster_text4 {
    font-size: 1.25em;
    font-weight: bold;
}