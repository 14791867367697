#contact {
    padding: 5vh 0;
}

.contact_container {
    width: 100%;
    text-align: center;
}

.contact_heading {
    font-family: Salsa, serif;
    font-size: 3.0em;
    color: #05253C;
}

.contact_point_text_section {
    background-color: #65E4C5;
    text-align: left;
    padding-left: 30px;
    padding-right: 70px;
}

.contact_point_text {
    font-size: 1.17em;
    margin: 10px 0 10px 10px;
    font-weight: bold;
    color: white;
    font-family: Mukta, serif;
}

.contact_point_icon {
    color: white;
}

.contact_point {
    display: inline-flex;
    align-items: center;
    background-color: #05253C;
    margin-bottom: 20px;
    padding: 0 20px;
    width: 100%;
}

#contact_form_submit {
    background-color: #65E4C5;
    color: #05253C;
    width: 100%;
    text-align: center;
    border: none;
    border-radius: 5px;
    height: 40px;
    font-size: 16px;
    cursor: pointer;
    font-family: Mukta, serif;
}